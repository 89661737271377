@charset "UTF-8";
@tailwind base;
@tailwind components;

body:not(.tt-active) .tt {
  display: none !important;
}

/* .typetester-overlay-container {
  transition: transform 1s ease-in-out;
  transform: scale3d(1.8, 1.8, 1);
  pointer-events: none;
} */

/* .typetester-overlay-container > div {
  pointer-events: initial;
}

.typetester-overlay-active .typetester-overlay-container {
  transition: transform 0.2s ease-in-out;
  transform: scale(100%);
}

.tt__bg {
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
}

.typetester-overlay-active .tt__bg {
  opacity: 1;
} */

/* body:not(.typetester-overlay-active) .tt__sample {
  display: none !important;
}

body:not(.typetester-overlay-active) .tt__tv {
  display: none !important;
} */

/* div[data-component*="preset"].hidden {
  transform: translateY(-100%);
  transition: transform 1s;
}

div[data-component*="preset"]:not(.hidden) {
  transform: translateY(0%);
  transition: transform 1s;
} */

/* .typetester-control-area-deactivated.typetester-control-area-left {
  transition: transform 0.2s ease-in-out;
  transform: translateX(-120%);
}

.typetester-control-area-deactivated.typetester-control-area-bottom {
  transition: transform 0.2s ease-in-out;
  @apply translate-y-[420%] md:translate-y-[110%];
}

.typetester-control-area-deactivated.typetester-control-area-bottom-mobileonly {
  transition: transform 0.2s ease-in-out;
  @apply translate-y-[110%] md:translate-y-0;
} */

.tt-element-container {
  @apply flex items-center bg-black border border-white p-2 text-xs;
}

.tt-button {
  @apply mx-2 rounded-[5px] h-[27px] bg-white text-black px-4;
}

.tt-button-toggle {
  @apply mx-0 rounded-[5px] h-[27px] bg-white text-black px-4;
}

.tt-button-toggle:nth-of-type(1) {
  @apply rounded-r-none;
}

.tt-button-toggle:nth-of-type(2) {
  @apply rounded-l-none mr-2;
}

.tt-button-anim-on {
  @apply bg-white text-black;
}

.tt-button-anim-off {
  @apply bg-red text-white;
}

.tt-button-active .tt-button-anim-on {
  @apply bg-red text-white;
}

.tt-button-active .tt-button-anim-off {
  @apply bg-white text-black;
}

[data-component="tt__setup"]:nth-of-type(1) {
  @apply rounded-[5px] mr-3;
}

[data-component="tt__setup"]:nth-of-type(2) {
  @apply rounded-[5px];
}

[data-component="tt__setup"].tt-button-active {
  @apply bg-red text-white;
}

[data-component="tt__setup"].tt-button-inactive {
  @apply bg-white text-black;
}

.tt-single-item-button {
  @apply hover:rounded-[5px] transition-all px-4;
}

.typetester-slider {
  @apply min-w-[12vw] appearance-none h-1 bg-white outline-none;
}

.typetester-slider::-webkit-slider-thumb {
  @apply bg-red appearance-none w-[20px] h-[20px] cursor-pointer rounded-[2px] hover:bg-white;
}

.typetester-slider::-moz-range-thumb {
  @apply w-[20px] h-[20px] bg-red cursor-pointer rounded-[2px] hover:bg-white;
}

.tt__sample {
  will-change: font-variation-settings, font-family, font-size;
  font-variation-settings: "wght" var(--user-wght), "wdth" var(--user-wdth),
    "ital" var(--user-ital);
}

.tt__sample[data-setup="proportional"] {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" var(--user-wght-proportional),
    "wdth" var(--user-wdth-proportional), "ital" var(--user-ital-proportional);
}

.tt__sample[data-setup="mono"] {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" var(--user-wght-mono),
    "wdth" var(--user-wdth-mono), "ital" var(--user-ital-mono);
}

.tt__sample {
  transition: transform 0.4s ease-in-out;
  transform: translateY(-100%);
}

.tt[data-setup="proportional"] .tt__sample[data-setup="proportional"] {
  transform: translateY(0%);
}

.tt[data-setup="mono"] .tt__sample[data-setup="mono"] {
  transform: translateY(0%);
}
/*
[data-setup="mono"] [data-component="tt__control-area"] .wdth-slider {
  @apply -translate-x-[calc(100%+45px)] transition-transform;
}

[data-setup="mono"] [data-component="tt__control-area"] .tt-element-container:first-of-type {
  @apply -translate-x-[calc(32%)] transition-transform;
}

[data-setup="proportional"] [data-component="tt__control-area"] .tt-element-container:first-of-type {
  @apply transition-transform;
} */

[data-setup="mono"] [data-component="tt__control-area"] .wdth-slider {
  @apply opacity-20 pointer-events-none transition-opacity;
}

/* WORKAROUND FOR CHROME BUG v100+ (currently up to 104) that crashes after VAR animations with too many axes */

.browser-flag-chrome-anim-disable [data-component="tt__autopilot"] {
  @apply hidden;
}