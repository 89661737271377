nav {
  will-change: background-color, color, border-color;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  background-color: var(--section-background-color);
  color: var(--section-text-color);
  border-color: var(--section-text-color);
  transform: translateY(-100%);
}

nav.nav--is-visible {
  transform: translateY(0);
}
