@charset "UTF-8";

/* --- GT PRESSURA VAR FONTS ––– */

/* @font-face {
  font-family: "GT Pressura Proportional";
  src: url("../../fonts/GT-Pressura-VF.woff2") format("woff2-variations"),
    url("../../fonts/GT-Pressura-VF.ttf") format("truetype-variations");
  font-weight: normal;
}

@font-face {
  font-family: "GT Pressura Mono";
  src: url("../../fonts/GT-Pressura-Mono-VF.woff2") format("woff2-variations"),
    url("../../fonts/GT-Pressura-Mono-VF.ttf") format("truetype-variations");
  font-weight: normal;
}  */

/* GT Pressura Standard & Extended */

.font-std-light {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 300, "wdth" 100, "ital" 0;
}

.font-std-text {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 350, "wdth" 100, "ital" 0;
}

.font-std-regular {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 400, "wdth" 100, "ital" 0;
}

.font-std-medium {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
}

.font-std-bold {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 700, "wdth" 100, "ital" 0;
}

.font-std-black {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 900, "wdth" 100, "ital" 0;
}

.font-std-light-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 300, "wdth" 100, "ital" 1;
}

.font-std-text-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 350, "wdth" 100, "ital" 1;
}

.font-std-regular-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 400, "wdth" 100, "ital" 1;
}

.font-std-medium-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 500, "wdth" 100, "ital" 1;
}

.font-std-bold-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 700, "wdth" 100, "ital" 1;
}

.font-std-black-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 900, "wdth" 100, "ital" 1;
}

.font-ext-light {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 300, "wdth" 150, "ital" 0;
}

.font-ext-text {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 350, "wdth" 150, "ital" 0;
}

.font-ext-regular {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 400, "wdth" 150, "ital" 0;
}

.font-ext-medium {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 500, "wdth" 150, "ital" 0;
}

.font-ext-bold {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 700, "wdth" 150, "ital" 0;
}

.font-ext-black {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 900, "wdth" 150, "ital" 0;
}

.font-ext-light-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 300, "wdth" 150, "ital" 1;
}

.font-ext-text-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 350, "wdth" 150, "ital" 1;
}

.font-ext-regular-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 400, "wdth" 150, "ital" 1;
}

.font-ext-medium-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 500, "wdth" 150, "ital" 1;
}

.font-ext-bold-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 700, "wdth" 150, "ital" 1;
}

.font-ext-black-italic {
  font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 900, "wdth" 150, "ital" 1;
}

/* GT Pressura Mono */

.font-mono-light {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 300, "ital" 0;
}

.font-mono-text {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 350, "ital" 0;
}

.font-mono-regular {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 400, "ital" 0;
}

.font-mono-medium {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 500, "ital" 0;
}

.font-mono-bold {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 700, "ital" 0;
}

.font-mono-black {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 900, "ital" 0;
}

.font-mono-light-italic {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 300, "ital" 1;
}

.font-mono-text-italic {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 350, "ital" 1;
}

.font-mono-regular-italic {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 400, "ital" 1;
}

.font-mono-medium-italic {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 500, "ital" 1;
}

.font-mono-bold-italic {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 700, "ital" 1;
}

.font-mono-black-italic {
  font-family: "GT Pressura Mono";
  font-variation-settings: "wght" 900, "ital" 1;
}

h1,
.h1 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-weight: normal;
  font-variation-settings: "wght" 500, "wdth" 150, "ital" 0;
  font-size: 52px;
  line-height: 60px;
  font-weight: normal;
  @apply text-black;
}

h2,
.h2 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-weight: normal;
  @apply text-3xl lg:text-2xl font-ext-bold tracking-tight;
}

h3,
.h3 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-weight: normal;
  font-variation-settings: "wght" 400, "wdth" 150, "ital" 0;
  font-size: 24px;
  line-height: 26px;
}

h4,
.h4 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-size: 44px;
  line-height: 48px;
  /* font-family: "GT Pressura Proportional";
  font-variation-settings: "wght" 700, "wdth" 150, "ital" 0; */
  @apply text-md lg:text-xl tracking-tight leading-none pb-6 font-ext-bold;
}

h4.font-mono-bold,
.h4.font-mono-bold {
  font-family: "GT Pressura Mono", "Comic Sans MS", cursive;
}

h5,
.h5 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-size: 34px;
  line-height: 41px;
}

h6,
.h6 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-size: 24px;
  line-height: 35.52px;
}

h7,
.h7 {
  font-family: "GT Pressura Proportional", "Comic Sans MS", cursive;
  font-size: 18px;
  line-height: 21.6px;
}

section.bg-black p a {
  @apply text-gray hover:text-white;
}

section.bg-red p a {
  @apply text-black hover:text-beige before:content-['↗\202F'];
}

section.bg-white p a {
  @apply text-gray hover:text-black;
}

/* Text Styles */

/* @layer base { */
.caps {
  @apply uppercase tracking-[0.02em];
}
/* } */

.text-body {
  @apply font-std-text text-sm md:text-rg;
}

.text-body-ext {
  @apply font-ext-text;
}

.text-body-mono {
  @apply font-mono-text;
}

.text-body p,
.text-body-ext p,
.text-body-mono p {
  @apply pb-5;
}

.caption-container {
  @apply text-xxs font-mono-regular caps md:text-sm lg:text-xs;
}

.caption {
  @apply pt-5 lg:pt-0 lg:absolute lg:bottom-0 lg:w-max;
}

.caption-left {
  @apply lg:left-0 lg:-rotate-[90deg] lg:origin-bottom-left lg:pb-4;
}

.caption-right {
  @apply lg:right-0 lg:-rotate-[270deg] lg:origin-bottom-right lg:pb-4;
}

.text-italic {
  font-variation-settings: "wght" inherit, "wdth" inherit, "ital" 1;
}

em {
  @apply font-normal text-italic;
}

/* OpenType features */

.ot-onum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
}

.ot-onum-tnum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
}

.ot-lnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
}

.ot-lnum-tnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
}

.ot-dlig {
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
  font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
}

.ot-sups {
  text-transform: lowercase;
  -webkit-font-feature-settings: "kern" 1, "sups" 1;
  font-feature-settings: "kern" 1, "sups" 1;
}

.ot-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
}

.ot-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
}

.ot-tf-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
}

.ot-tf-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
}

.ot-osf {
  -webkit-font-feature-settings: "onum" 1, "kern" 1;
  font-feature-settings: "onum" 1, "kern" 1;
}

.ot-ssplusarrow {
  -webkit-font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1,
    "ss04" 1;
  font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1;
}

.ot-salt {
  -webkit-font-feature-settings: "calt" 1, "kern" 1;
  font-feature-settings: "calt" 1, "kern" 1;
}

.ot-smcp {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1;
  font-feature-settings: "smcp" 1, "kern" 1;
}

.ot-case {
  -webkit-font-feature-settings: "case" 1, "kern" 1;
  font-feature-settings: "case" 1, "kern" 1;
}

.ot-ss01 {
  -webkit-font-feature-settings: "ss01" 1, "kern" 1;
  font-feature-settings: "ss01" 1, "kern" 1;
}

.ot-aalt {
  -webkit-font-feature-settings: "aalt" 1, "kern" 1;
  font-feature-settings: "aalt" 1, "kern" 1;
}