@charset "UTF-8";

.js-animation-paused,
.js-animation-paused * {
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

.animation-lottie {
  @apply relative;
}

.is-init #loader-section {
  @apply -translate-y-full transition-transform;
}

/* STORY SECTION */

.anim-story-std-ext {
  font-family: "GT Pressura Extended Anim";
}

/* .anim-std-ext-slider {
  animation: animation-var-slider 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-var-slider {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: calc(100% - 20px);
  }
}

.anim-story-ext-light {
  animation: animation-story-ext-light 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-story-ext-light {
  0% {
    font-variation-settings: "wdth" 100, "wght" 300, "ital" 0;
  }
  100% {
    font-variation-settings: "wdth" 150, "wght" 300, "ital" 0;
  }
}

.anim-story-ext-text {
  animation: animation-story-ext-text 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-story-ext-text {
  0% {
    font-variation-settings: "wdth" 100, "wght" 350, "ital" 0;
  }
  100% {
    font-variation-settings: "wdth" 150, "wght" 350, "ital" 0;
  }
}

.anim-story-ext-regular {
  animation: animation-story-ext-regular 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-story-ext-regular {
  0% {
    font-variation-settings: "wdth" 100, "wght" 400, "ital" 0;
  }
  100% {
    font-variation-settings: "wdth" 150, "wght" 400, "ital" 0;
  }
}

.anim-story-ext-medium {
  animation: animation-story-ext-medium 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-story-ext-medium {
  0% {
    font-variation-settings: "wdth" 100, "wght" 500, "ital" 0;
  }
  100% {
    font-variation-settings: "wdth" 150, "wght" 500, "ital" 0;
  }
}

.anim-story-ext-bold {
  animation: animation-story-ext-bold 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-story-ext-bold {
  0% {
    font-variation-settings: "wdth" 100, "wght" 700, "ital" 0;
  }
  100% {
    font-variation-settings: "wdth" 150, "wght" 700, "ital" 0;
  }
}

.anim-story-ext-black {
  animation: animation-story-ext-black 2s infinite alternate ease-in-out;
  animation-play-state: paused;
}

@keyframes animation-story-ext-black {
  0% {
    font-variation-settings: "wdth" 100, "wght" 900, "ital" 0;
  }
  100% {
    font-variation-settings: "wdth" 150, "wght" 900, "ital" 0;
  }
} */

/* DESIGN SECTION */

/* @keyframes animation-var-wght-slider {
  0% {
    margin-left: 0%;
  }
  25% {
    margin-left: calc(100% - 20px);
  }
  75% {
    margin-left: calc(100% - 20px);
  }
} */

.anim-design-var-slider {
  font-family: "GT Pressura All Axes Anim";
  animation: animation-design-var 4s infinite ease-in-out;
}

@keyframes animation-design-var {
  0% {
    font-variation-settings: "wght" 300, "wdth" 100, "ital" 0;
  }
  25% {
    font-variation-settings: "wght" 900, "wdth" 100, "ital" 0;
  }
  50% {
    font-variation-settings: "wght" 900, "wdth" 150, "ital" 0;
  }
  75% {
    font-variation-settings: "wght" 900, "wdth" 150, "ital" 1;
  }
  100% {
    font-variation-settings: "wght" 300, "wdth" 100, "ital" 0;
  }
} 

.anim-design-wght-slider {
  animation: animation-var-wght-slider 4s infinite ease-in-out;
}

@keyframes animation-var-wght-slider {
  0% {
    margin-left: 0%;
  }
  25% {
    margin-left: calc(100% - 20px);
  }
  50% {
    margin-left: calc(100% - 20px);
  }
  75% {
    margin-left: calc(100% - 20px);
  }
  100% {
    margin-left: 0%;
  }
}

.anim-design-wdth-slider {
  animation: animation-var-wdth-slider 4s infinite ease-in-out;
}

@keyframes animation-var-wdth-slider {
  0% {
    margin-left: 0%;
  }
  25% {
    margin-left: 0%;
  }
  50% {
    margin-left: calc(100% - 20px);
  }
  75% {
    margin-left: calc(100% - 20px);
  }
  100% {
    margin-left: 0%;
  }
}

.anim-design-ital-slider {
  animation: animation-var-ital-slider 4s infinite ease-in-out;
}

@keyframes animation-var-ital-slider {
  0% {
    margin-left: 0%;
  }
  25% {
    margin-left: 0%;
  }
  50% {
    margin-left: 0%;
  }
  75% {
    margin-left: calc(100% - 20px);
  }
  100% {
    margin-left: 0%;
  }
}

/* CHARACTER SET */

.anim-charset-glyph {
  font-family: "GT Pressura Charset";
  animation: animation-charset-glyph 2s infinite ease-in-out alternate;
}

@keyframes animation-charset-glyph {
  0% {
    font-variation-settings: "wght" 300, "wdth" 100, "ital" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "wdth" 100, "ital" 0;
  }
} 

.charset-anim-vmetric-xheight {
  animation: animation-charset-vmetric-xheight 2s infinite ease-in-out alternate;
}

@keyframes animation-charset-vmetric-xheight {
  0% {
    @apply top-[36.8%];
  }
  100% {
    @apply top-[35.3%];
  }
} 

/* TYPE TESTER */

.tt--autopilot-active [data-component="tt__sample"][data-setup="proportional"] {
  animation: animation-tt-autopilot-prop 4s infinite ease-in-out;
}

@keyframes animation-tt-autopilot-prop {
  0% {
    font-variation-settings: "wght" 300, "wdth" 100, "ital" 0;
  }
  25% {
    font-variation-settings: "wght" 900, "wdth" 100, "ital" 0;
  }
  50% {
    font-variation-settings: "wght" 900, "wdth" 150, "ital" 0;
  }
  75% {
    font-variation-settings: "wght" 900, "wdth" 150, "ital" 1;
  }
  100% {
    font-variation-settings: "wght" 300, "wdth" 100, "ital" 0;
  }
}

.tt--autopilot-active [data-component="tt__sample"][data-setup="mono"] {
  animation: animation-tt-autopilot-mono 2.66s infinite ease-in-out;
}

@keyframes animation-tt-autopilot-mono {
  0% {
    font-variation-settings: "wght" 300, "ital" 0;
  }
  33% {
    font-variation-settings: "wght" 900, "ital" 0;
  }
  66% {
    font-variation-settings: "wght" 900, "ital" 1;
  }
  100% {
    font-variation-settings: "wght" 300, "ital" 0;
  }
}