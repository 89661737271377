[data-component="intro-style-overview"] {
  --font-variation-wght: 500;
  --font-variation-ital: 0;
  --font-variation-wdth: 100;
  touch-action: manipulation;
}

[data-component="intro-style-overview__sample"] {
  user-select: none;
  touch-action: manipulation;

  transition: font-variation-settings 0.3s ease-in-out;
  font-variation-settings: "ital" var(--font-variation-ital),
    "wght" var(--font-variation-wght), "wdth" var(--font-variation-wdth);
}

[data-component="intro-style-overview"] li,
[data-component="intro-style-overview"] li span {
  user-select: none;
  touch-action: manipulation;
}

[data-wght="300"][data-ital="0"]
  [data-component="intro-style-overview__style"][data-wght="300"][data-ital="0"] {
  color: #fff;
}

[data-wght="300"][data-ital="1"]
  [data-component="intro-style-overview__style"][data-wght="300"] {
  color: #fff;
}

[data-wght="350"][data-ital="0"]
  [data-component="intro-style-overview__style"][data-wght="350"][data-ital="0"] {
  color: #fff;
}

[data-wght="350"][data-ital="1"]
  [data-component="intro-style-overview__style"][data-wght="350"] {
  color: #fff;
}

[data-wght="400"][data-ital="0"]
  [data-component="intro-style-overview__style"][data-wght="400"][data-ital="0"] {
  color: #fff;
}

[data-wght="400"][data-ital="1"]
  [data-component="intro-style-overview__style"][data-wght="400"] {
  color: #fff;
}

[data-wght="500"][data-ital="0"]
  [data-component="intro-style-overview__style"][data-wght="500"][data-ital="0"] {
  color: #fff;
}

[data-wght="500"][data-ital="1"]
  [data-component="intro-style-overview__style"][data-wght="500"] {
  color: #fff;
}

[data-wght="700"][data-ital="0"]
  [data-component="intro-style-overview__style"][data-wght="700"][data-ital="0"] {
  color: #fff;
}

[data-wght="700"][data-ital="1"]
  [data-component="intro-style-overview__style"][data-wght="700"] {
  color: #fff;
}

[data-wght="900"][data-ital="0"]
  [data-component="intro-style-overview__style"][data-wght="900"][data-ital="0"] {
  color: #fff;
}

[data-wght="900"][data-ital="1"]
  [data-component="intro-style-overview__style"][data-wght="900"] {
  color: #fff;
}

/* @media (hover: hover) {
  [data-component="intro-style-overview"] li:hover span:first-child {
    color: #fff;
  }

  [data-component="intro-style-overview"] li:hover span:last-child:hover {
    color: #fff;
  }
}

@media (hover: none) {
  [data-component="intro-style-overview"] li:active span:first-child {
    color: #fff;
  }

  [data-component="intro-style-overview"] li:active span:last-child:active {
    color: #fff;
  }
} */
