[data-component="animated-width__current-val"]:before {
  content: var(--width-val);
}

[data-component="animated-width__letter"] div:first-child {
  font-variation-settings: "wght" var(--font-variation-wght),
    "wdth" var(--font-variation-wdth);
}

[data-component="animated-width__hr"] {
  top: var(--top);
}
