@charset "UTF-8";
@tailwind base;

.family-overview-table-mobile {
  table-layout: fixed;
  white-space: fixed;
  width: calc(100vw - 30px);
  @apply select-none;
  width: 100%;
}

.family-overview-table-desktop {
  table-layout: fixed;
  white-space: fixed;
  width: calc(100vw - 30px);
  @apply select-none;
}

@media only screen and (min-width: 768px) {
  .family-overview-table-desktop {
    width: calc(100vw - 50px);
  }
}

@media only screen and (min-width: 1024px) {
  .family-overview-table-desktop {
    width: calc(100vw - 300px);
  }
}

@media only screen and (min-width: 1280px) {
  .family-overview-table-desktop {
    width: calc(100vw - 450px);
  }
}

td span {
  pointer-events: none;
}

th,
td {
  @apply border;
}

th,
th div {
  @apply text-xs leading-5;
}

.ov-mono th,
.ov-standard th,
.ov-extended th {
}

th div {
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateX(-1em);
}

@media only screen and (min-width: 1024px) {
  th div {
    @apply min-h-[5em] md:min-h-[12em] lg:min-h-[6em] xl:min-h-[10em] xxl:min-h-[14em];
  }
}

td {
  @apply text-[12vw] md:text-[4.5vw] lg:text-[4.5vw] xl:text-[6vw] text-center;
}

tr:nth-of-type(n + 2) {
  @apply h-[6em] md:h-auto;
}

.hide-on-mobile {
  display: none;
}

@media only screen and (min-width: 1100px) {
  .hide-on-mobile {
    display: inline;
  }
}

.empty-cell {
  border-top: 0px solid black;
  border-left: 0px solid black;
}

.family-overview-table {
  --color: red;
}

.family-overview-table td {
  will-change: font-variation-settings, font-family;
}

.family-overview-table-mobile td:nth-of-type(1) {
  --font-family: "GT Pressura Mono";
  --font-variation-wdth: 100;
}

.family-overview-table-mobile td:nth-of-type(2) {
  --font-family: "GT Pressura Proportional";
  --font-variation-wdth: 100;
}

.family-overview-table-mobile td:nth-of-type(3) {
  --font-family: "GT Pressura Proportional";
  --font-variation-wdth: 150;
}

.family-overview-table-mobile tr:nth-of-type(2) {
  --font-variation-wght: 300;
}

.family-overview-table-mobile tr:nth-of-type(3) {
  --font-variation-wght: 350;
}

.family-overview-table-mobile tr:nth-of-type(4) {
  --font-variation-wght: 400;
}

.family-overview-table-mobile tr:nth-of-type(5) {
  --font-variation-wght: 500;
}

.family-overview-table-mobile tr:nth-of-type(6) {
  --font-variation-wght: 700;
}

.family-overview-table-mobile tr:nth-of-type(7) {
  --font-variation-wght: 900;
}

.family-overview-table-desktop tr:nth-of-type(2) {
  --font-family: "GT Pressura Mono";
  --font-variation-wdth: 100;
}

.family-overview-table-desktop tr:nth-of-type(3) {
  --font-family: "GT Pressura Proportional";
  --font-variation-wdth: 100;
}

.family-overview-table-desktop tr:nth-of-type(4) {
  --font-family: "GT Pressura Proportional";
  --font-variation-wdth: 150;
}

.family-overview-table-desktop td:nth-of-type(1) {
  --font-variation-wght: 300;
}

.family-overview-table-desktop td:nth-of-type(2) {
  --font-variation-wght: 350;
}

.family-overview-table-desktop td:nth-of-type(3) {
  --font-variation-wght: 400;
}

.family-overview-table-desktop td:nth-of-type(4) {
  --font-variation-wght: 500;
}

.family-overview-table-desktop td:nth-of-type(5) {
  --font-variation-wght: 700;
}

.family-overview-table-desktop td:nth-of-type(6) {
  --font-variation-wght: 900;
}

table td {
  font-family: var(--font-family);
  font-variation-settings: "ital" 0, "wght" var(--font-variation-wght),
    "wdth" var(--font-variation-wdth);
}

/* table.active td {
  font-variation-settings: "ital" 0, "wght" var(--font-variation-wght),
    "wdth" var(--font-variation-wdth);
} */
