@charset "UTF-8";

@font-face {
  font-family: "font-loader";
  src: url("../../fonts/GT-Pressura-Loader.woff2") format("woff2");
  font-weight: normal;
}

.font-loader {
  font-family: "font-loader";
}
