[data-component="extended-subfamily__slider"] {
  will-change: transform;
  transform: translateX(var(--slider-translate));
}

[data-component="extended-subfamily__text"] {
  will-change: font-variation-settings;
  font-variation-settings: "wght" var(--font-variation-wght),
    "wdth" var(--font-variation-wdth);
}

[data-component="extended-subfamily__text"]:nth-child(1) {
  --font-variation-wght: 300;
}

[data-component="extended-subfamily__text"]:nth-child(2) {
  --font-variation-wght: 350;
}

[data-component="extended-subfamily__text"]:nth-child(3) {
  --font-variation-wght: 400;
}

[data-component="extended-subfamily__text"]:nth-child(4) {
  --font-variation-wght: 500;
}

[data-component="extended-subfamily__text"]:nth-child(5) {
  --font-variation-wght: 700;
}

[data-component="extended-subfamily__text"]:nth-child(6) {
  --font-variation-wght: 900;
}
